<template lang="pug">
.container
  .wallet-form-box.card
    .card-body
      .row
        .col-md-6
          h4 Nepal Electricity Authority
          transition(name="fade", mode="out-in")
            .form-group(v-if="message")
              .show-message {{ message }}
          transition(name="fade", mode="out-in")
            .ml-3(v-if="messageDetails")
              .mt-3
                .row.mt-3
                  label.text-capitalize Customer Name
                  input.form-control.form-control-sm(
                    :value="messageDetails.CustomerName"
                    disabled
                  )
                .row.mt-3
                  label Customer ID
                  input.form-control.form-control-sm(:value="messageDetails.ConsumerId" disabled)
                .row.mt-3
                  label Office
                  input.form-control.form-control-sm(:value="messageDetails.Office" disabled)
                .row.mt-3
                  label Bill Detail
                  div(v-html="messageDetails.formattedBillDetail" class="table table-striped")
                .row.mt-3
                  label Service Charge
                  input.form-control.form-control-sm(:value="neaDetails.ServiceCharge" disabled)
                .row.mt-3
                  label Total Due Amount
                  input.form-control.form-control-sm(:value="neaDetails.Amount + neaDetails.ServiceCharge" disabled)
              div
                show-bank(
                  :bankClicked="bankClicked",
                  :banks="banks",
                  v-if="!loading"
                )
            form(@submit.prevent="getDetails", v-else)
              .form-group
                label Counter
                  i.fa.fa-asterisk
                select.form-control(
                  v-model="neaDetails.OfficeCode",
                  :disabled="loading",
                  required
                )
                  option(:value="null", disabled) Select Office
                  option(
                    v-for="counter in allCounter",
                    :key="counter.OfficeCodes",
                    :value="counter.OfficeCodes"
                  ) {{ counter.Office }}
                .form-group
                label Enter Subscriber No.
                  i.fa.fa-asterisk
                input.form-control(
                  type="text",
                  v-model="neaDetails.SCNo",
                  required
                )
              .form-group
                label Customer ID
                  i.fa.fa-asterisk
                input.form-control(
                  type="text",
                  v-model="neaDetails.ConsumerId",
                  required
                )
              .form-group
                button.btn.btn-danger(:disabled="loading") Get Details
        .col-md-6
          pin-confirm(
            v-if="paymentModal",
            :previewDetails="previewDetails",
            :pinVerification="verifyPin"
          )
          .mt-3
            img(
              v-if="!messageDetails",
              :src="BASE_URL + 'assets/img/info-nea.png'"
            )
  loading-spinner(:loading="loading")
</template>

<script>
import ShowBank from "@/components/ShowBank";
import LoadingSpinner from "@/components/LoadingSpinner";
import PinConfirm from "@/components/PinConfirm";
import api from "@/helpers/api";
import axios from "@/axios";
import payment from "@/mixins/payment";
export default {
  mixins: [payment],
  name: "utility-nea",
  components: { ShowBank, LoadingSpinner, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Subscriber Number": this.neaDetails.SCNo,
        "Customer ID": this.neaDetails.ConsumerId,
        "Total Amount to Pay":
          "Rs. " + ((this.neaDetails.Amount || 0) + (this.neaDetails.ServiceCharge || 0)),
      };
    },
  },
  data: function () {
    return {
      message: "",
      allCounter: "",
      neaDetails: {
        ConsumerId: "",
        SCNo: "",
        OfficeCode: null,
        ServiceCharge: 0,
        TotalDueAmount: 0,
      },
      messageDetails: "",
      banks: "",
      loading: true,
      BASE_URL: process.env.BASE_URL,
    };
  },
  methods: {
    verifyPin(verified) {
      this.neaDetails.pin = verified;
      this.pinVerification(this.makePayment, verified);
    },
    bankClicked(bank) {
      if (this.neaDetails.Amount !== "0.00") {
        this.neaDetails.bank = bank;
        if (this.pinVerified) this.makePayment();
        else {
          this.paymentModal = true;
          axios
            .get("rewards/calculate", {
              params: {
                amount: this.neaDetails.amount,
                servicesId: this.neaDetails.servicesId,
              },
            })
            .then((res) => {
              if (res.data && res.data.data) {
                this.neaDetails.reward = res.data.data.earningRewards;
              }
            });
        }
      } else {
        this.message = "You dont have any due amount..";
      }
    },
    makePayment() {
      this.loading = true;
      axios
        .post("api/v4/nea/payment", this.neaDetails)
        .then((res) => {
          if (res.data) {
            if (res.data.status === 1) {
              this.message = res.data.message;
              this.messageDetails = "";
              this.preview = false;
              if (res.data.status === 1) this.$store.dispatch("UPDATE_BALANCE");
            } else {
              this.message = res.data.message;
            }
            this.loading = false;
          }
        })
        .catch((_) => {
          this.message = "Something went wrong.";
          this.loading = false;
        });
    },

    getDetails() {
      this.message = "";
      this.loading = true;
      axios
        .post("api/v2/services/nea/bill", this.neaDetails)
        .then(async (r) => {
          if (r.data && r.data.response && r.data.response.Code === "000") {
            if (r.data.response.Code) {
              let messageDetails = r.data.response;
              this.neaDetails.Amount = messageDetails.TotalDueAmount;
              axios
                .post("api/v2/services/nea/charge", this.neaDetails)
                .then((chargeRes) => {
                  this.messageDetails = r.data.response;
                  this.neaDetails.ServiceCharge = chargeRes.data?.response?.SCharge ?? 0;
                })
                .catch((er) => {
                  this.messageDetails = r.data.response;
                });
            }
          } else if (r.data.data.message) {
            this.message = r.data.data.message;
          }
          this.loading = false;
        })
        .catch((er) => {
          console.log(er);
          this.message = "Something went wrong";
          this.loading = false;
        });
    },

    /**
     * Beautify Nea Details
     * @param{array} rawArray array contains information in key value pair in form of array.
     *
     * This function converts array value pair in object key value pair.
     * Formatting <br>'s into html table structure.
     */
    beautifyNeaDetails(rawArray, serviceCharge = 0) {
      let formatted = {};
      rawArray.forEach((inn) => {
        formatted[inn.keyTitle.replace(/-/g, " ")] = inn.valueTitle;
      });
      formatted["More Information"] = formatted["#info"].replace("3", "2");
      formatted["service charge"] = serviceCharge;
      formatted["total payable amount"] =
        parseFloat(formatted["total due amount"]) + parseFloat(serviceCharge);
      delete formatted["message"];

      let info = formatted["#info"].split("/>")[1];
      info = info.split("-------------------------------");
      let headings = [];
      let data = [];
      info[0].split("<br>").forEach((i) => {
        if (typeof headings[i] === "undefined") headings.push(i.split(":")[0]);
      });
      info.forEach((i) => {
        let one = [];
        i.split("<br>").forEach((b) => {
          one.push(b.split(":")[1]);
        });
        data.push(one);
      });
      let table = `<table class='table table-striped table-sm table-responsive-sm'><tr>`;
      headings.forEach((hh) => {
        if (typeof hh !== "undefined" && hh != "") table += `<th>${hh}</th>`;
      });
      table += `</tr>`;
      data.forEach((dOne) => {
        table += "<tr>";
        dOne.forEach((row) => {
          if (typeof row !== "undefined") table += `<td>${row}</td>`;
        });

        table += "</tr>";
      });
      table += `</table>`;
      formatted["More Information"] = table;
      delete formatted["#info"];
      return formatted;
    },
  },
  async mounted() {
    this.neaDetails.servicesId = await api.serviceIdBySlug("NEPAL_ELECTRICITY");
    let offices = await api.neaOfficeCodes(this.neaDetails.servicesId);
    if (offices) {
      this.allCounter = offices;
      this.banks = await api.banks();
    } else {
      this.message = "Check your internet Connection.";
    }
    this.loading = false;
  },
};
</script>
